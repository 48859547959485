 @media print {

    @page {        
        margin-left: 2cm;
        margin-right: 2cm;
        margin-top: 1cm;
        margin-bottom: 1.5cm;
    }

    .video,
    .audio,
    .portlet-column-content.empty,
    .alert-info,
    .alert-danger,
    .portlet-msg-help,
    .portlet-msg-info,
    .portlet-msg-progress,
    .-hide-live,
    .btn,
    .o-page-module--border:not(:last-child):after,
    .o-slick-controls__icon,
    .o-richtext iframe,
    .c-comments,
    .c-page-fotter,
    .c-page-head__touch-field--search,
    .c-page-head__touch-field--menu,
    .c-page-intro__meta--bottom,
    .c-page-intro__form-languageselect,
    .c-page-context-contacts,
    .c-page-context--no-border,
    .c-page-context-contacts,
    .c-page-context--veranstaltungdetail,
    .c-promo,
    .c-tagcloud,
    .c-aside-teaser--links,
    .c-aside-teaser--covermedia,
    .c-aside-teaser--relatedVeranstaltungen,
    .c-aside-teaser--publications,
    .c-aside-teaser--medienobjekte,
    .c-aside-teaser--events,
    .c-aside-teaser__alert,
    .c-aside-teaser__cta,
    .c-aside-teaser__entry--map,
    .c-page-context-contacts,
    .c-share,
    .c-share--aside-new,
    .c-share__wrapper--aside,
    .c-share__wrapper {
        display: none !important;
    }

    #navigation,
    #download-programm-text,
    #event-backlinks-print,
    #footer {
        display: none !important;
    }

    video,
    audio {
        display: none !important;
    }

    #wrapper {
        margin-top: 2px !important;
    }
    
    //---
    .o-page-module--aside {
        width: 100%;

        .c-aside-teaser {
            background: none;
        }

        .o-cta {
            display: none;
        }
    }

    .c-promo {
        background: none;

        &:before,
        &:after {
            display: none;
        }

        &__text {
            color: #444;
        }
    }

    body {
        font-size: 16px;
        counter-reset: page;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 22px;
        margin-bottom: 10px !important;
        color: black !important;
    }

    h3 {
        font-size: 18px;
    }

    h4, h5, h6 {
        font-size: 18px !important;
        font-weight: bold;
    }
    //---
    // KAs-3742 new:

    .o-page-module {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .o-page-layout-marginal__main {
        width: unset;
        margin-left: unset !important;
    }

    .o-page-layout-marginal__marginal {
        width: 100%;
        margin-right: unset !important;
        border-bottom: unset;
    }

    .o-page-layout-marginal__content {
        padding-bottom: 10px;
        display: block;
        border-bottom: unset;
    }

    .o-banner span {
        line-height: 1.5 !important;
    }

    .o-banner--booked-up:after {
        background-color: #FF4B5E !important;
    }
    .o-banner--canceled:after {
        background-color: #FF4B5E !important;
    }

    .o-banner-for-print {
        background-color: #FF4B5E !important;
    }

    .c-page-context--presented-by {
        display: block !important;
        page-break-inside: avoid;
    }

    .c-page-head__main {
        height: 70px !important;
    }

    .c-page-intro__image {
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }

    .c-page-intro__author {
        color: #004682 !important;
    }

    .c-page-intro__subheadline {
        color: #19588e !important;
    }

    .c-page-intro__copy {
        margin-bottom: unset !important;
        color: #004682 !important;
    }

    .c-page-context--presented-by .c-page-context__copy {
        color: #004682 !important;
        font-size: 19px;
        font-weight: 400;
        text-align: center;
    }

    .c-aside-teaser__entry {
        padding-bottom: unset !important;
        page-break-inside: avoid;
    }

    .c-aside-teaser--contacts,
    .c-aside-teaser--ansprechpartner {
        page-break-inside: avoid;
    }

    .c-aside-teaser {
        background-color: unset !important;
        margin-bottom: unset !important;
        padding: unset !important;
    }

    .c-aside-teaser__headline {
        padding-bottom: unset !important;
    }

    .c-program {
        margin-top: 2rem !important;
    }

    .c-program__image {
        margin: 1.5rem 0rem !important;
    }

    .c-program .c-page-main__headline {
        margin-bottom: 2rem !important;
    }

    .c-page-main .o-media__image-alt {
        background-color: #e5ecf2 !important;
    }

    .c-program .c-program__text {
        visibility: visible;
        text-align: justify;
        opacity: 1;
        max-height: max-content;
        padding: 1.5rem !important;
    }

    .c-program .c-program__text.-active {
        margin-bottom: unset;
    }

    .c-page-main__text a {
        color: unset !important;
    }

    .c-partner__image {
        max-width: 400px;
        padding: 4px;
    }

    .c-image-gallery__item {
        display: block !important;
        page-break-inside: avoid;
    }

    .c-image-gallery__text-area {
        background: #e5ecf2 !important;
    }

    .o-imagesource {
        color: #fff !important;
        text-shadow: 1px 1px 2px #000 !important;
    }

    .o-accordion__trigger {
        background: #004682 !important;
    }

    .o-accordion__arrow .o-icon__arrow-down {
        display: none !important;
    }

    .slick-slide {
        display: none !important;
        &.slick-active {
            display: block !important;
        }
    }

    .slick-list {
        height: auto !important;
    }

    .slick-track {
        width: auto !important;
        height: auto !important;
        transform: none !important;
        .slick-slide {
            width: auto !important;
        }
    }

    a[href]:after {
        content: unset !important;
    }

    *,
    *:before,
    *:after {
        background-color: inherit !important;
        color: unset !important;
        box-shadow: unset !important;
        text-shadow: unset !important;
    }

}